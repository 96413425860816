import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import JointSettings from '../../JointSettings.js';

import JointItem from '../items/JointItem.js';
import JointItemConf from '../items/JointItemConf.js';
import { JointItemTypes } from '../items/JointTypes.js';

class JointBollardAxis {
	constructor(jointMain, topAxisHeight, leftAxisWidth, bollards) {
		this.jointMain = jointMain;

		this.bollards = bollards;

		this.topAxisHeight = topAxisHeight;
		this.leftAxisWidth = leftAxisWidth;

		if (this.bollards.length == 0) {
			this.topAxisItemWidth = this.jointMain.paperWidth - this.leftAxisWidth;
		} else {
			this.topAxisItemWidth = (this.jointMain.paperWidth - this.leftAxisWidth) / this.bollards.length;
		}
	}

	render() {
		this.renderInitialInvisibleBollard();
		this.renderBollards();
		this.renderFinalInvisibleBollard();
		this.renderBollardDelimiters();
	}

	// PRIMERA COLUMNA - MEDIO BOLARDO INVISIBLE (centrar labels en los ejes)
	renderInitialInvisibleBollard() {
		var jsItemConf = new JointItemConf(JointItemTypes.Bollard, '', 0, 0, this.leftAxisWidth - this.topAxisItemWidth / 2, this.topAxisHeight);

		var invisibleBollard = new JointItem(jsItemConf, this.jointMain);
		invisibleBollard.render();
	}

	// RENDER BOLARDOS
	renderBollards() {
		for (let n = 0; n < this.bollards.length; n++) {
			var jsItemConf = new JointItemConf(
				JointItemTypes.Bollard,
				this.bollards[n].bolcode,
				this.leftAxisWidth - this.topAxisItemWidth / 2 + this.topAxisItemWidth * n,
				0,
				this.topAxisItemWidth,
				this.topAxisHeight
			);

			var bollard = new JointItem(jsItemConf, this.jointMain);
			bollard.render();

			this.jointMain.jointZManager.setItem(bollard);
		}
	}

	// MEDIO BOLARDO INVISIBLE FINAL
	renderFinalInvisibleBollard() {
		var jsItemConf = new JointItemConf(
			JointItemTypes.Bollard,
			'',
			this.leftAxisWidth - this.topAxisItemWidth / 2 + this.topAxisItemWidth * this.bollards.length,
			0,
			this.topAxisItemWidth / 2,
			this.topAxisHeight
		);

		var invisibleBollard = new JointItem(jsItemConf, this.jointMain);
		invisibleBollard.render();

		this.jointMain.jointZManager.setItem(invisibleBollard);
	}

	// DELIMITADORES DE BOLARDOS
	renderBollardDelimiters() {
		for (let n = 1; n < this.bollards.length; n++) {
			var line = V('line', {
				x1: this.leftAxisWidth + n * this.topAxisItemWidth,
				y1: 0,
				x2: this.leftAxisWidth + n * this.topAxisItemWidth,
				y2: this.jointMain.paperHeight,
				stroke: JointSettings.colors.grid.lines,
				'stroke-width': 1
			});

			V(this.jointMain.paper.viewport).append(line);
		}
	}

	getXpositionFromTopItemCode(bolcode) {
		for (let n = 0; n < this.bollards.length; n++) {
			if (bolcode == this.bollards[n].bolcode) {
				//return (this.bollards[n].orderby - 1) * this.topAxisItemWidth;
				return this.leftAxisWidth + n * this.topAxisItemWidth;
			}
		}
		return null;
	}

	getClosestBollardFromXposition(x) {
		let leftBollard;
		let rightBollard;

		let leftBollardX;
		let rightBollardX;

		if (this.bollards && this.bollards.length > 2) {
			for (let n = 0; n < this.bollards.length - 1; n++) {
				leftBollard = this.bollards[n];
				rightBollard = this.bollards[n + 1];

				if (x < this.getXpositionFromTopItemCode(rightBollard.bolcode)) {
					break;
				}
			}

			leftBollardX = this.getXpositionFromTopItemCode(leftBollard.bolcode);
			rightBollardX = this.getXpositionFromTopItemCode(rightBollard.bolcode);
		}

		return x - leftBollardX < rightBollardX - x ? leftBollard : rightBollard;
	}
}

export default JointBollardAxis;
