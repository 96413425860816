import { dia, ui, shapes, elementTools, util, V, g } from '@clientio/rappid';
import JointSettings from '../../JointSettings.js';

import JointItem from '../items/JointItem.js';
import JointItemConf from '../items/JointItemConf.js';
import { JointItemTypes } from '../items/JointTypes.js';

class JointBuoyAxis {
	constructor(jointMain, topAxisHeight, leftAxisWidth, buoys) {
		this.jointMain = jointMain;

		this.buoys = buoys;

		this.topAxisHeight = topAxisHeight;
		this.leftAxisWidth = leftAxisWidth;

		if (this.buoys.length == 0) {
			this.buoyWidth = this.jointMain.paperWidth - this.leftAxisWidth;
		} else {
			this.buoyWidth = (this.jointMain.paperWidth - this.leftAxisWidth) / this.buoys.length;
		}
	}

	render() {
		this.renderInitialInvisibleBuoy();
		this.renderBuoys();
		this.renderBuoyDelimiters();
	}

	// PRIMERA COLUMNA
	renderInitialInvisibleBuoy() {
		var jsItemConf = new JointItemConf(JointItemTypes.Buoy, '', 0, 0, this.buoyWidth / 2, this.topAxisHeight);

		var invisibleBuoy = new JointItem(jsItemConf, this.jointMain);
		invisibleBuoy.render();
	}

	// RENDER BOLARDOS
	renderBuoys() {
		for (let n = 0; n < this.buoys.length; n++) {
			var jsItemConf = new JointItemConf(
				JointItemTypes.Buoy,
				this.buoys[n].bolcode,
				this.leftAxisWidth + this.buoyWidth * n,
				0,
				this.buoyWidth,
				this.topAxisHeight
			);

			var buoy = new JointItem(jsItemConf, this.jointMain);
			buoy.render();
		}
	}

	// DELIMITADORES DE BOLARDOS
	renderBuoyDelimiters() {
		for (let n = 0; n < this.buoys.length; n++) {
			var line = V('line', {
				x1: this.leftAxisWidth + n * this.buoyWidth,
				y1: 0,
				x2: this.leftAxisWidth + n * this.buoyWidth,
				y2: this.jointMain.paperHeight,
				stroke: JointSettings.colors.grid.lines,
				'stroke-width': 1
			});

			V(this.jointMain.paper.viewport).append(line);
		}
	}

	getXpositionFromTopItemCode(bolcode) {
		for (let n = 0; n < this.buoys.length; n++) {
			if (bolcode == this.buoys[n].bolcode) {
				//return (this.buoys[n].orderby - 1) * this.buoyWidth;
				return this.leftAxisWidth + n * this.buoyWidth;
			}
		}
		return null;
	}

	getClosestBollardFromXposition(x) {
		let leftBuoy;
		let rightBuoy;

		let leftBuoyX;
		let rightBuoyX;

		if (this.buoys && this.buoys.length > 2) {
			for (let n = 0; n < this.buoys.length - 1; n++) {
				leftBuoy = this.buoys[n];
				rightBuoy = this.buoys[n + 1];

				if (x < this.getXpositionFromTopItemCode(rightBuoy.bolcode)) {
					break;
				}
			}

			leftBuoyX = this.getXpositionFromTopItemCode(leftBuoy.bolcode);
			rightBuoyX = this.getXpositionFromTopItemCode(rightBuoy.bolcode);
		}

		return x - leftBuoyX < rightBuoyX - x ? leftBuoy : rightBuoy;
	}
}

export default JointBuoyAxis;
