export const JointBoardTypes = Object.freeze({
	// Berth planner
	AnchorageBerth: Symbol('anchorageBerth'),
	BollardBerth: Symbol('bollardBerth'),
	BuoyBerth: Symbol('buoyBerth'),

	// Resource planner
	ResourcePlanner: Symbol('resourcePlanner')
});

export const JointItemTypes = Object.freeze({
	// Berth planner
	Bollard: Symbol('bollard'),
	Buoy: Symbol('buoy'),
	DayOfWeek: Symbol('dayOfWeek'),
	Month: Symbol('month'),
	HourOfDay: Symbol('hourOfDay'),
	DayForecast: Symbol('dayForecast'),
	Vessel: Symbol('vessel'),
	BerthBlock: Symbol('berthBlock'),
	Booking: Symbol('booking'),

	// Resource planner
	ResourceHour: Symbol('resourceHour'),
	ResourceName: Symbol('resourceName'),
	ResourceCode: Symbol('resourceCode'),
	ResourceAllocation: Symbol('resourceAllocation'),
	ResourceActualAllocation: Symbol('resourceActualAllocation'),
	ResourceBlock: Symbol('resourceBlock')
});
