<template>
	<div v-resize="onResize">
		<div id="bp_container"></div>

		<v-tooltip v-for="(stop, index) in stops" :key="index" right>
			<template v-slot:activator="{ on, attrs }">
				<div v-on="on" v-bind="attrs">
					<div v-if="stop.status != 1">
						<v-icon :id="'vesselTooltipIcon_' + stop.id" style="visibility: hidden !important; font-size: 20px">
							fas fa-info-circle
						</v-icon>
					</div>
				</div>
			</template>
			<berth-planner-vessel-tooltip :id="'vesselTooltip_' + stop.id" :stop="stops[index]" />
		</v-tooltip>

		<v-dialog v-model="vesselDialog" max-width="900" eager>
			<berth-planner-vessel-dialog
				v-if="vesselDialog"
				:vessel="vesselDialogStop"
				v-on:close-dialog="closeVesselDialog()"
				:readOnly="readOnly"
				:userFunctionalities="userFunctionalities"
			/>
		</v-dialog>
	</div>
</template>

<script>
import JointMain from '../jointjs/JointMain.js';
import JointBollardBerthBoard from '../jointjs/components/bollardberthplanner/JointBollardBerthBoard.js';
import JointBuoyBerthBoard from '../jointjs/components/buoyberthplanner/JointBuoyBerthBoard.js';
import { JointBoardTypes, JointItemTypes } from '../jointjs/components/items/JointTypes.js';
import JointUtils from '../jointjs/components/utils/JointUtils.js';

import BerthPlannerVesselTooltip from './vessel/BerthPlannerVesselTooltip.vue';
import BerthPlannerVesselDialog from './vessel/BerthPlannerVesselDialog.vue';

import WeatherService from '@/services/weather.service.js';

export default {
	components: { BerthPlannerVesselTooltip, BerthPlannerVesselDialog },
	props: {
		value: {
			type: Object,
			required: true
		},

		firstWeekDay: {
			type: Date,
			required: true
		},
		lastWeekDay: {
			type: Date,
			required: true
		},
		dailyForecast: {
			type: Array
		},
		currentBerth: {
			type: Object,
			required: true
		},
		userFunctionalities: {
			type: Object,
			required: true
		},
		readOnly: {
			type: Boolean,
			required: true
		}
	},
	data() {
		return {
			debug: false,

			numberOfDays: null,
			dayOfWeekLiterals: this.getDayOfWeekLiterals(),
			now: new Date(),

			jointMain: null,
			jointBoard: null,

			bollards: [],
			days: [],
			stops: [],
			blocks: [],
			bookings: [],

			vesselDialogStop: null,
			vesselDialog: false
		};
	},
	computed: {
		onPuiExpandMenu() {
			return this.$store.state.menu.expandMenu;
		},
		onPuiSecondMenuShowed() {
			return this.$store.state.menu.secondMenuShowed;
		},
		isManuallySettedBuoyBerth() {
			return this.bollards[0] && this.bollards[0].berthid == 3480;
		},
		boardType() {
			return this.isManuallySettedBuoyBerth ? JointBoardTypes.BuoyBerth : JointBoardTypes.BollardBerth;
		}
	},
	watch: {
		value() {
			this.stops = this.value.stops;
			this.blocks = this.value.blocks;
			this.bookings = this.value.bookings;
			this.setBoard();
		},
		readOnly() {
			this.setBoard();
		}
	},
	created() {
		this.$puiEvents.$on('berthplanner-vesselDialog_update', (updatedStop) => {
			this.updateItemValuesFromVueEvent(updatedStop);
		});
	},
	async mounted() {
		this.stops = this.value.stops;
		this.blocks = this.value.blocks;
		this.bookings = this.value.bookings;

		this.getDayRange();
		await this.getBollardsAxisModel();
		this.getDaysAxisModel();

		this.setBoard();
		this.loadSavedConfiguration();
	},
	destroyed() {
		//this.$puiEvents.$off('berthplanner-vesselDialog_update');
	},
	methods: {
		getDayRange() {
			const difference = this.lastWeekDay.getTime() - this.firstWeekDay.getTime();
			this.numberOfDays = Math.round(difference / (1000 * 60 * 60 * 24));
		},
		getDayOfWeekLiterals() {
			let dayOfWeekLiterals = [];
			for (let i = 0; i < 7; i++) {
				dayOfWeekLiterals.push(this.$t(`common.weekday.${i}`));
			}
			return dayOfWeekLiterals;
		},
		async getBollardsAxisModel() {
			const params = {
				portid: this.currentBerth && this.currentBerth.portid ? this.currentBerth.portid : 0,
				berthid: this.currentBerth && this.currentBerth.id ? this.currentBerth.id : 0
			};
			await this.$puiRequests.getRequest(
				this.$store.getters.getModelByName('berth').url.getBollardsByBerth,
				params,
				(response) => {
					this.bollards = response.data;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		getDaysAxisModel() {
			for (let i = 0; i < this.numberOfDays; i++) {
				let newDay = new Date(this.firstWeekDay);

				if (i > 0) {
					newDay.setDate(newDay.getDate() + i);
				}

				const dayForecast = this.getForecastModel(newDay);

				this.days.push({
					date: newDay,
					weekDayLabel: this.dayOfWeekLiterals[newDay.getDay()],
					dayLabel: `${newDay.getDate()}/${newDay.getMonth() + 1}`,
					forecastInfo: dayForecast && dayForecast.Operations,
					locale: this.$store.getters.getUserLanguage
				});
			}
		},
		getForecastModel(date) {
			if (this.dailyForecast && Array.isArray(this.dailyForecast)) {
				for (let i = 0; i < this.dailyForecast.length; i++) {
					const dayForecast = this.dailyForecast[i];
					dayForecast.date = new Date(dayForecast.dt * 1000);

					if (
						date.getDate() === dayForecast.date.getDate() &&
						date.getMonth() === dayForecast.date.getMonth() &&
						date.getFullYear() === dayForecast.date.getFullYear()
					) {
						dayForecast.Operations = {};
						dayForecast.Operations.date = dayForecast.date;

						dayForecast.Operations.windSpeed = Math.round(dayForecast.wind_speed * 10) / 10;
						dayForecast.Operations.windDirection = WeatherService.getWindDirectionFromWindDegrees(dayForecast.wind_deg);
						dayForecast.Operations.temperatureLabel = Math.round(dayForecast.temp.day * 10) / 10 + 'ºC';
						dayForecast.Operations.windLabel = dayForecast.Operations.windSpeed + ' m/s (' + dayForecast.Operations.windDirection + ')';

						return dayForecast;
					}
				}
			}

			return null;
		},
		setBoard() {
			this.jointMain = new JointMain('bp_container', this.$puiEvents, this.$puiI18n);
			this.jointMain.userFunctionalities = this.userFunctionalities;
			this.jointMain.readOnly = this.readOnly;

			if (this.boardType == JointBoardTypes.BollardBerth) {
				this.jointBoard = new JointBollardBerthBoard(
					this.jointMain,
					this.days,
					this.now,
					this.bollards,
					this.stops,
					this.blocks,
					this.bookings
				);
			}

			if (this.boardType == JointBoardTypes.BuoyBerth) {
				this.jointBoard = new JointBuoyBerthBoard(this.jointMain, this.days, this.now, this.bollards, this.stops, this.blocks, this.bookings);
			}

			// console.log(this.jointBoard.boardItems);

			const self = this;
			this.jointMain.paper.on('cell:pointerdown', function (cellView, evt, x, y) {
				if (
					cellView.model.operationsModel &&
					(cellView.model.operationsModel.itemType == JointItemTypes.Vessel ||
						cellView.model.operationsModel.itemType == JointItemTypes.Booking)
				) {
					self.jointBoard.boardItems.forEach((boardItem) => {
						if (cellView.model.id == boardItem.itemView.model.id) {
							self.jointMain.jointZManager.toFront(boardItem);
						}
					});
				}
			});

			this.jointMain.paper.on('cell:pointerdblclick', function (cellView, evt, x, y) {
				if (
					cellView.model.operationsModel &&
					(cellView.model.operationsModel.itemType == JointItemTypes.Vessel ||
						cellView.model.operationsModel.itemType == JointItemTypes.Booking)
				) {
					self.showVesselDialog(cellView.model.operationsModel.stop);
				}
			});

			//this.jointMain.paper.on('cell:pointerup', function (cellView, evt, x, y) {});

			this.jointBoard.boardItems.forEach((boardItem) => {
				if (boardItem.operationsModel.itemType != JointItemTypes.BerthBlock && boardItem.operationsModel.stop) {
					this.setTooltipPosition(boardItem.operationsModel.stop.id, boardItem.operationsModel.position);
				}

				boardItem.rectangle.on('change:position', (element, position) => {
					if (boardItem.operationsModel.stop) {
						this.setTooltipPosition(boardItem.operationsModel.stop.id, element.attributes.position);
					}

					// POSITION RESTRICTIONS

					const roundedBoardItemLeftBoundary = Math.round(element.attributes.position.x);
					const roundedBoardItemWidth = Math.round(element.attributes.size.width);
					const roundedBoardItemRightBoundary = Math.round(element.attributes.position.x + roundedBoardItemWidth);
					this.debug && console.log('// change:position');
					this.debug && console.log('roundedLeftBoundary ' + this.jointBoard.roundedLeftBoundary);
					this.debug && console.log('roundedBoardItemLeftBoundary ' + roundedBoardItemLeftBoundary);
					this.debug && console.log('roundedBoardItemWidth ' + roundedBoardItemWidth);
					this.debug && console.log('roundedBoardItemRightBoundary ' + roundedBoardItemRightBoundary);
					this.debug && console.log('roundedRightBoundary ' + this.jointBoard.roundedRightBoundary);

					if (roundedBoardItemLeftBoundary < this.jointBoard.roundedLeftBoundary) {
						this.debug && console.log('// position restriction left limit');
						boardItem.rectangle.position(this.jointBoard.roundedLeftBoundary, position.y);
					}

					if (roundedBoardItemRightBoundary > this.jointBoard.roundedRightBoundary) {
						this.debug && console.log('// position restriction right limit');
						boardItem.rectangle.position(this.jointBoard.rightBoundary - roundedBoardItemWidth, position.y);
					}

					// END POSITION RESTRICTIONS

					boardItem.operationsModel.board.dateini = this.jointBoard.jointLeftAxis.getDateFromYPosition(element.attributes.position.y);
					boardItem.operationsModel.board.dateend = this.jointBoard.jointLeftAxis.getDateFromYPosition(
						element.attributes.position.y + element.attributes.size.height
					);

					boardItem.operationsModel.board.bollardini = this.jointBoard.jointTopAxis.getClosestBollardFromXposition(
						element.attributes.position.x
					);
					boardItem.operationsModel.board.bollardend = this.jointBoard.jointTopAxis.getClosestBollardFromXposition(
						element.attributes.position.x + element.attributes.size.width
					);

					let x1;
					if (boardItem.operationsModel.board.bollardini) {
						x1 = this.jointBoard.jointTopAxis.getXpositionFromTopItemCode(boardItem.operationsModel.board.bollardini.bolcode);
						x1 = Math.round(x1);
					} else {
						x1 = this.jointBoard.roundedLeftBoundary;
					}

					if (x1 + roundedBoardItemWidth > this.jointBoard.roundedRightBoundary) {
						this.debug && console.log('** bollard fit size correction from position event');
						boardItem.rectangle.resize(this.jointBoard.roundedRightBoundary - x1, element.attributes.size.height);
					}

					this.debug && console.log('** bollard fit from position event');
					boardItem.rectangle.position(x1, position.y);

					boardItem.resizeImages();
					this.checkIntersections();
					this.updateItemValuesFromJointEvent(boardItem);
				});

				boardItem.rectangle.on('change:size', (element, size) => {
					// SIZE RESTRICTIONS

					const roundedBoardItemLeftBoundary = Math.round(element.attributes.position.x);
					const roundedBoardItemWidth = Math.round(size.width);
					const roundedBoardItemRightBoundary = Math.round(element.attributes.position.x + roundedBoardItemWidth);
					this.debug && console.log('// change:size');
					this.debug && console.log('roundedLeftBoundary ' + this.jointBoard.roundedLeftBoundary);
					this.debug && console.log('roundedBoardItemLeftBoundary ' + roundedBoardItemLeftBoundary);
					this.debug && console.log('roundedBoardItemWidth ' + roundedBoardItemWidth);
					this.debug && console.log('roundedBoardItemRightBoundary ' + roundedBoardItemRightBoundary);
					this.debug && console.log('roundedRightBoundary ' + this.jointBoard.roundedRightBoundary);

					// minimum width
					if (size.width < this.jointBoard.jointTopAxis.topAxisItemWidth) {
						this.debug && console.log('// size restriction minimum limit');
						boardItem.rectangle.resize(this.jointBoard.jointTopAxis.topAxisItemWidth, size.height);
					}

					// maximum height
					if (size.width > this.jointBoard.maxVesselWidth) {
						this.debug && console.log('// size restriction maximum limit');
						boardItem.rectangle.resize(this.jointBoard.maxVesselWidth, size.height);
					}

					// right overflow
					if (roundedBoardItemRightBoundary > this.jointBoard.roundedRightBoundary) {
						this.debug && console.log('// size restriction right overflow');
						boardItem.rectangle.position(this.jointBoard.roundedRightBoundary - roundedBoardItemWidth, element.attributes.position.y);
					}

					// END SIZE RESTRICTIONS

					boardItem.operationsModel.board.dateini = this.jointBoard.jointLeftAxis.getDateFromYPosition(element.attributes.position.y);
					boardItem.operationsModel.board.dateend = this.jointBoard.jointLeftAxis.getDateFromYPosition(
						element.attributes.position.y + element.attributes.size.height
					);

					boardItem.operationsModel.board.bollardini = this.jointBoard.jointTopAxis.getClosestBollardFromXposition(
						element.attributes.position.x
					);
					boardItem.operationsModel.board.bollardend = this.jointBoard.jointTopAxis.getClosestBollardFromXposition(
						element.attributes.position.x + size.width
					);

					if (!boardItem.operationsModel.board.bollardini) {
						boardItem.operationsModel.board.bollardini = this.bollards[0];
					}

					if (!boardItem.operationsModel.board.bollardend) {
						boardItem.operationsModel.board.bollardend = this.bollards[this.bollards.length - 1];
					}

					let x1 = this.jointBoard.jointTopAxis.getXpositionFromTopItemCode(boardItem.operationsModel.board.bollardini.bolcode);
					let x2 = this.jointBoard.jointTopAxis.getXpositionFromTopItemCode(boardItem.operationsModel.board.bollardend.bolcode);
					let vesselWidth = Math.round(x2) - Math.round(x1);

					if (vesselWidth < this.jointBoard.jointTopAxis.topAxisItemWidth) {
						vesselWidth = this.jointBoard.jointTopAxis.topAxisItemWidth;
					}
					if (vesselWidth > this.jointBoard.maxVesselWidth) {
						vesselWidth = this.jointBoard.maxVesselWidth;
					}
					if (x1 + vesselWidth > this.jointBoard.roundedRightBoundary) {
						vesselWidth = this.jointBoard.roundedRightBoundary - Math.round(vesselWidth);
					}

					this.debug && console.log('** bollard fit from size event');
					boardItem.rectangle.resize(vesselWidth, size.height);

					boardItem.resizeImages();
					this.checkIntersections();
					this.updateItemValuesFromJointEvent(boardItem);
				});
			});

			this.checkIntersections();
		},
		setTooltipPosition(id, position) {
			let bpContainer = document.getElementById('bp_container');
			let toolTipIcon = document.getElementById('vesselTooltipIcon_' + id);

			let menuBarWidth = 64;
			if (this.onPuiExpandMenu || this.onPuiSecondMenuShowed) {
				menuBarWidth = 304;
			}
			let margin = 5;

			if (toolTipIcon) {
				toolTipIcon.style.position = 'absolute';
				if (
					position.y < this.jointBoard.topAxisHeight ||
					bpContainer.getBoundingClientRect().top + margin + position.y + 20 + margin >
						bpContainer.getBoundingClientRect().top + bpContainer.getBoundingClientRect().height
				) {
					toolTipIcon.style.visibility = 'hidden';
				} else {
					toolTipIcon.style.visibility = 'visible';
					toolTipIcon.style.opacity = '0';
				}
				toolTipIcon.style.transition = 'none';
				toolTipIcon.style.top = bpContainer.getBoundingClientRect().top + margin + position.y + 'px';
				toolTipIcon.style.left = bpContainer.getBoundingClientRect().left - menuBarWidth + margin + position.x + 'px';
				toolTipIcon.style.zIndex = 99;
			}
		},
		checkIntersections() {
			this.jointBoard.boardItems.forEach((boardItem) => {
				if (boardItem.operationsModel.itemType == JointItemTypes.Vessel) {
					boardItem.operationsModel.validBerth = true;
					boardItem.setOriginalColor();
				}

				if (Object.keys(boardItem.operationsModel.board).length > 0 && boardItem.operationsModel.stop) {
					boardItem.setModifiedColor();
				}
			});

			let colorableVesselStatus = ['AC', 'AU'];

			this.jointBoard.boardItems.forEach((boardItem1) => {
				this.jointBoard.boardItems.forEach((boardItem2) => {
					if (boardItem1 != boardItem2) {
						let intersection = boardItem1.getGRect().intersect(boardItem2.getGRect());
						if (intersection !== null && !(boardItem1.operationsModel.deleted || boardItem2.operationsModel.deleted)) {
							boardItem1.operationsModel.validBerth = false;
							boardItem2.operationsModel.validBerth = false;

							if (boardItem1.operationsModel.stop && colorableVesselStatus.includes(boardItem1.operationsModel.stop.statusid))
								boardItem1.setInvalidColor();

							if (boardItem2.operationsModel.stop && colorableVesselStatus.includes(boardItem2.operationsModel.stop.statusid))
								boardItem2.setInvalidColor();
						}
					}
				});
			});
		},
		updateItemValuesFromVueEvent(updatedStop) {
			/*
			this.jointBoard.boardItems.forEach((vessel) => {
				if (vessel.operationsModel.stop && vessel.operationsModel.stop.id == updatedStop.id) {
					vessel.setSecondLabel(JointUtils.getSecondLabelFromStop(updatedStop));
					vessel.calculateBerthing();
					this.setBoard();
				}
			});
			*/
		},
		updateItemValuesFromJointEvent(boardItem) {
			this.$parent.savingEnabled = true;

			if (boardItem.operationsModel.itemType == JointItemTypes.Vessel) {
				this.stops.forEach((stop) => {
					if (stop.id == boardItem.operationsModel.stop.id) {
						if (boardItem.operationsModel.board.bollardini) {
							stop.bollardiniid = boardItem.operationsModel.board.bollardini.id;
							stop.bollardinicode = boardItem.operationsModel.board.bollardini.bolcode;
							stop.bollardiniorderby = boardItem.operationsModel.board.bollardini.orderby;
						} else {
							stop.bollardiniid = null;
							stop.bollardinicode = null;
							stop.bollardiniorderby = null;
						}

						if (boardItem.operationsModel.board.bollardend) {
							stop.bollardendid = boardItem.operationsModel.board.bollardend.id;
							stop.bollardendcode = boardItem.operationsModel.board.bollardend.bolcode;
							stop.bollardendorderby = boardItem.operationsModel.board.bollardend.orderby;
						} else {
							stop.bollardendid = null;
							stop.bollardendcode = null;
							stop.bollardendorderby = null;
						}

						stop.etalocal = boardItem.operationsModel.board.dateini;
						stop.etdlocal = boardItem.operationsModel.board.dateend;
						stop.eta = stop.etaplanner = stop.etalocal.toISOString();
						stop.etd = stop.etdplanner = stop.etdlocal.toISOString();

						boardItem.setSecondLabel(JointUtils.getSecondLabelFromStop(stop, boardItem.itemView.model.attributes.size.height));
						boardItem.setLabel(JointUtils.getLabelFromStop(stop, boardItem.itemView.model.attributes.size.height));
					}
				});
			}

			if (boardItem.operationsModel.itemType == JointItemTypes.BerthBlock) {
				this.blocks.forEach((berthBlock) => {
					if (berthBlock.id == boardItem.operationsModel.berthblock.id) {
						if (boardItem.operationsModel.board.bollardini) {
							berthBlock.bollardiniid = boardItem.operationsModel.board.bollardini.id;
							berthBlock.bollardinicode = boardItem.operationsModel.board.bollardini.bolcode;
							berthBlock.bollardiniorderby = boardItem.operationsModel.board.bollardini.orderby;
						} else {
							berthBlock.bollardiniid = null;
							berthBlock.bollardinicode = null;
							berthBlock.bollardiniorderby = null;
						}

						if (boardItem.operationsModel.board.bollardend) {
							berthBlock.bollardendid = boardItem.operationsModel.board.bollardend.id;
							berthBlock.bollardendcode = boardItem.operationsModel.board.bollardend.bolcode;
							berthBlock.bollardendorderby = boardItem.operationsModel.board.bollardend.orderby;
						} else {
							berthBlock.bollardendid = null;
							berthBlock.bollardendcode = null;
							berthBlock.bollardendorderby = null;
						}

						berthBlock.dateini = boardItem.operationsModel.board.dateini.toISOString();
						berthBlock.dateend = boardItem.operationsModel.board.dateend.toISOString();

						boardItem.setSecondLabel(JointUtils.getSecondLabelFromBlock(berthBlock, boardItem.itemView.model.attributes.size.height));
						boardItem.setLabel(JointUtils.getLabelFromBlock(berthBlock, boardItem.itemView.model.attributes.size.height));
					}
				});
			}

			if (boardItem.operationsModel.itemType == JointItemTypes.Booking) {
				this.bookings.forEach((booking) => {
					if (booking.id == boardItem.operationsModel.booking.id) {
						if (boardItem.operationsModel.board.bollardini) {
							booking.bollardiniid = boardItem.operationsModel.board.bollardini.id;
							booking.bollardinicode = boardItem.operationsModel.board.bollardini.bolcode;
							booking.bollardiniorderby = boardItem.operationsModel.board.bollardini.orderby;
						} else {
							booking.bollardiniid = null;
							booking.bollardinicode = null;
							booking.bollardiniorderby = null;
						}

						if (boardItem.operationsModel.board.bollardend) {
							booking.bollardendid = boardItem.operationsModel.board.bollardend.id;
							booking.bollardendcode = boardItem.operationsModel.board.bollardend.bolcode;
							booking.bollardendorderby = boardItem.operationsModel.board.bollardend.orderby;
						} else {
							booking.bollardendid = null;
							booking.bollardendcode = null;
							booking.bollardendorderby = null;
						}

						booking.etalocal = booking.etaplannerlocal = boardItem.operationsModel.board.dateini;
						booking.etdlocal = booking.etdplannerlocal = boardItem.operationsModel.board.dateend;
						booking.eta = booking.etaplanner = booking.etalocal.toISOString();
						booking.etd = booking.etdplanner = booking.etdlocal.toISOString();

						boardItem.setSecondLabel(JointUtils.getSecondLabelFromBooking(booking, boardItem.itemView.model.attributes.size.height));
						boardItem.setLabel(JointUtils.getLabelFromBooking(booking, boardItem.itemView.model.attributes.size.height));
					}
				});
			}

			this.$emit('input', { stops: this.stops, blocks: this.blocks, bookings: this.bookings });
		},
		loadSavedConfiguration() {
			const configuration = this.$store.getters.getBerthPlannerConfiguration;

			if (configuration.openedVesselDialogStopId) {
				this.stops.forEach((stop) => {
					if (stop.id == configuration.openedVesselDialogStopId) {
						this.showVesselDialog(stop);
					}
				});
			}
		},
		showVesselDialog(stop) {
			this.vesselDialogStop = Object.assign({}, stop);
			this.vesselDialog = stop.vesselimo || stop.vesselmmsi ? true : false;
			if (this.vesselDialog) {
				this.$store.state.berthPlanner.configuration.openedVesselDialogStopId = stop.id;
			}
		},
		closeVesselDialog() {
			this.$store.state.berthPlanner.configuration.openedVesselDialogStopId = null;
			this.vesselDialog = false;
		},
		refresh() {
			this.setBoard();
		},
		async onResize() {
			if (this.jointMain) {
				this.jointMain.initializePaper();
				this.jointMain.drawPaper();
				this.setBoard();
			}
		}
	}
};
</script>
